import React from 'react'
import { faBars } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Image from '../Image'

import styles from './AmazonSmile.module.scss'

import amazonSmileLogo from '../../public/amazon-smile-logo.png'

const AmazonSmile = ({}) => {
  return (
    <div className={styles.amazonSmile}>
      <div className={`${styles.amazonSmileContainer} container`}>
        <div className={styles.amazonSmileLeft}>
          <div className={styles.amazonSmileLogo}>
            <Image src={amazonSmileLogo} width={250} height={50} alt="Amazon Smile" />
          </div>
          <div className={styles.amazonSmileTitle}>New in the amazon app</div>
          <div className={styles.amazonSmileDescription}>Your app purchases can generate donations for us, at no extra cost to you!</div>
          <div className={styles.amazonSmileInfo}>Want to help make a difference while you shop in the Amazon app, at no extra cost to you? Simply follow the instructions below to select Breakeven as your charity and activate AmazonSmile in the app. Amazon will donate a portion of your eligible mobile app purchases to us.</div>
          <div className={styles.amazonSmileInfo}>How it works:</div>
          <div className={styles.amazonSmileSteps}>
            <div className={styles.amazonSmileStep}>1. Open the Amazon app</div>
            <div className={styles.amazonSmileStep}>2. Select the main menu <FontAwesomeIcon icon={faBars} /> & tap on {'"Amazon Smile"'} within {'"Programmes & Features"'}</div>
            <div className={styles.amazonSmileStep}>3. Select Breakeven as your charity</div>
            <div className={styles.amazonSmileStep}>4. Follow the on-screen instructions to activate Amazon Smile in the mobile app</div>
          </div>
        </div>
        <div className={styles.amazonSmileRight}>

        </div>
      </div>
    </div>
  )
}

export default AmazonSmile