import React, { ReactNode, useEffect } from 'react'
import Head from 'next/head'
import { motion } from 'framer-motion'
import { useRouter } from 'next/router'

import InfoSection from '../InfoSection'
import Button from '../Button'
import AmazonSmile from '../AmazonSmile'
import Footer from '../Footer'

const variants = {
  hidden: { opacity: 0, x: -200, y: 0 },
  enter: { opacity: 1, x: 0, y: 0 },
  exit: { opacity: 0, x: 0, y: -100 },
}

export default function PageLayout({
  title,
  description,
  children,
  showAmazon,
  showFooter = true,
  showContact = true,
  onAnimationComplete,
  onScroll
}: {
  title?: string
  description?: string
  children?: ReactNode
  showAmazon?: boolean
  showFooter?: boolean
  showContact?: boolean
  onAnimationComplete?: (definition: any) => void
  onScroll?: (offset: number, scrollId?: string) => void
}) {
  const router = useRouter()

  useEffect(() => {
    router.events.on('hashChangeComplete', handleHashChange)

    return () => {
      router.events.off('hashChangeComplete', handleHashChange)
    }
  }, [])

  const handleOnAnimationComplete = definition => {
    if (router && router.isReady && router.asPath) {
      handleHashChange(router.asPath)
    }

    if (onAnimationComplete) {
      onAnimationComplete(definition)
    }
  }

  const handleHashChange = url => {
    if (url && url.includes('#')) {
      const hash = url.split('#')[1]

      if (hash && hash.length > 0) {
        const hashParts = hash.split('&')
        const first = hashParts[0]

        if (first && first.length > 0) {
          if (onScroll) {
            const el = document.querySelector(`[data-scroll-id="${first}"]`) as HTMLElement
            
            if (el) {
              onScroll(el?.offsetTop - 90)
            }
          }
        }
      }
    }
  }

  return (
    <motion.div
      variants={variants}
      initial="hidden"
      animate="enter"
      exit="exit"
      transition={{
        type: 'linear'
      }}
      onAnimationComplete={handleOnAnimationComplete}
    >
      <Head>
        <title>{title || 'Breakeven: free counselling help for problem gambling'}</title>
        <meta name="description" content={description || 'Breakeven, a GamCare counselling partner, offers free counselling to anyone affected by problem gambling. Breakeven bases in Sussex, Kent, Essex, Suffolk and Lincolnshire, UK.'} />
      </Head>
      {children}
      {showFooter &&
        <>
          {showAmazon &&
            <AmazonSmile />
          }
          {showContact &&
            <InfoSection 
              title="WE CAN HELP"
              titleColour="#D2D2D2"
              description="Take a positive step forward and contact us now."
              colour="#ffffff"
              backgroundColour="#000000"
              button={<Button title="CONTACT US" colour="#D11A6D" backgroundColour="#ffffff" link="/contact" />}
            />
          }
        </>
      }
      <Footer />
    </motion.div>
  )
}